import { styled } from '@mui/system'
import { Swiper } from 'swiper/react'
import { Container } from '../../particles/slider/slider.styled'

const shouldForwardProp = (prop) =>
	!['count', 'direction', 'heightVertical', 'thumbsCount', 'verticalObjectPosition', 'aboveTheFold'].includes(prop)

const MainSliderContainer = styled('div', { shouldForwardProp })`
	${({ direction, heightVertical, verticalObjectPosition }) =>
		direction === 'vertical'
			? `
			.swiper-container {
				height: ${heightVertical};
				.swiper-slide {
					/*  first child of the slide */
					> * {
						height: 100%;
						/*  second child of the slide (normally a link) */
						> *:first-of-type {
							height: 100%;
							/*  third child of the slide (normally the parent of the picture) */
							> div {
								padding-bottom: 0;
							}
						}
					}
					img {
						object-fit: cover;
						object-position: ${verticalObjectPosition};
					}
				}
			}
		`
			: ''}
	${Container} {
		--swiper-navigation-size: 60px;
	}
	.swiper-button-prev,
	.swiper-button-next {
		font-size: ${({ theme }) => theme.typography.pxToRem(48)};
		padding: var(--spacing-1);
		z-index: 11;
		${({ theme }) => theme.breakpoints.down('md')} {
			bottom: 5.5%;
			top: auto;
			font-size: ${({ theme }) => theme.typography.pxToRem(30)};
			padding: 0;
		}
		${({ thumbsCount }) =>
			thumbsCount > 0
				? `
				bottom: 4.17%;
				top: auto;
			`
				: ''}
		${({ direction }) => (direction === 'vertical' ? 'margin-top: 0;' : '')}
	}
	.swiper-button-prev {
		${({ direction, thumbsCount }) => {
			return direction === 'vertical'
				? `
						bottom: 51.39%;
						left: auto;
						right: 4.17%;
						top: auto;
				  `
				: `left: ${thumbsCount > 0 ? 'auto' : '4.17%'};`
		}}
		${({ thumbsCount }) =>
			thumbsCount > 0
				? `
				right: 12%;
				${({ theme }) => theme.breakpoints.up('lg')} {
					right: 10%;
				}
			`
				: ''}
	}
	.swiper-button-next {
		right: 4.17%;
		${({ direction }) => (direction === 'vertical' ? 'top: 51.39%;' : '')}
	}
	.swiper-pagination.swiper-pagination-horizontal {
		bottom: calc(var(--spacing-1) / 2 + 5.8%);
	}
	.swiper-pagination.swiper-pagination-vertical {
		bottom: auto;
		left: calc(var(--spacing-1) / 2 + 3.61%);
		top: 50%;
		transform: translate3d(0px, -50%, 0);
		width: auto;
		.swiper-pagination-bullet {
			display: block;
			margin: calc(var(--spacing-1) / 2) 0;
		}
	}
`

const Root = styled('div', { shouldForwardProp })`
	position: relative;
	height: ${({ aboveTheFold }) => (aboveTheFold ? '100%' : undefined)};

	${MainSliderContainer} {
		height: ${({ aboveTheFold }) => (aboveTheFold ? '100%' : undefined)};
		${Container} {
			height: ${({ aboveTheFold }) => (aboveTheFold ? '100%' : undefined)};
			.swiper-container {
				height: ${({ aboveTheFold }) => (aboveTheFold ? '100%' : undefined)};
			}
		}
	}
`

const SliderThumbs = styled(Swiper)`
	bottom: 4.17%;
	left: 4.17%;
	position: absolute;
	width: ${({ count }) => 'calc(' + 5 * count + '% + ' + 31 * (count - 1) + 'px)'};
	${({ theme }) => theme.breakpoints.up('md')} {
		width: ${({ count }) => 'calc(' + 9.56 * count + '% + ' + 31 * (count - 1) + 'px)'};
	}
	.swiper-slide {
		cursor: pointer;
		&:before {
			border: 1px solid rgba(255, 255, 255, 0.6);
			bottom: 0;
			content: '';
			left: 0;
			position: absolute;
			right: 0;
			top: 0;
			z-index: 1;
		}
		&-thumb-active:before {
			border: 2px solid #fff;
		}
	}
`

export { MainSliderContainer, Root, SliderThumbs }
